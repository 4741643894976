import i18n from 'i18n-js';
import moment from 'moment';
import 'moment/locale/pl';

import PolishTranslations from './pl.json';
import EnglishTranslations from './pl.json';

i18n.translations = {
  pl: PolishTranslations,
  en: EnglishTranslations,
};

i18n.defaultLocale = 'pl';

i18n.missingTranslation = () => null;
i18n.pluralization.pl = count => {
  const mod10 = count % 10;
  const mod100 = count % 100;
  if (count === 0) return ['zero'];
  else if (count === 1) return ['one'];
  else if (mod10 >= 2 && mod10 <= 4 && (mod100 < 10 || mod100 > 20)) return ['few'];
  else return ['many'];
};

i18n.pluralization.en = count => {
  if (count === 0) return ['zero'];
  else if (count === 1) return ['one'];
  else if (count >= 2) return ['few'];
  else return ['many'];
};

moment.updateLocale('pl', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const t = (scope, option) => i18n.t(scope, option);
export default t;
