import React from 'react';
import classnames from 'classnames';

const Flex = ({ column, spaceAround, spaceBetween, children, className, style }) => {
  return (
    <div
      className={classnames('flex', className, {
        'flex-col': column,
        'justify-around': spaceAround,
        'justify-between': spaceBetween,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export default Flex;
