import React from 'react';
import classnames from 'classnames';

const NavButton = ({ open, onClick }) => (
  <div id="mobileNav" role="button" tabIndex={0} onKeyDown={onClick} onClick={onClick}>
    <div className={classnames('hamb-icon', { open })}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

export default NavButton;
