import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import t from '../../locales';

const Button = ({ to, children, disabled, content, className, small, onClick, active, style, primary, loading }) => {
  const getContent = () => {
    if (loading) return t('general.sending');
    return children || content;
  };
  return to ? (
    <Link
      to={to}
      className={classnames('button', className, { small, active, teal: primary, disabled: disabled })}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {getContent()}
    </Link>
  ) : (
    <button
      className={classnames('button', className, { small, active, teal: primary })}
      onClick={onClick}
      style={style}
      disabled={disabled || loading}
    >
      {getContent()}
    </button>
  );
};

Button.defaultProps = {
  small: null,
  primary: false,
  children: null,
  content: null,
  to: null,
  active: false,
  onClick: () => {},
  style: {},
  disabled: false,
};

Button.propTypes = {
  small: PropTypes.bool,
  primary: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.string,
  to: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default Button;
