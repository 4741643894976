import axios from 'axios';
import CryptoJS from 'crypto-js';
import { isoLocales } from '../locales/constans';

export function getInitParams(lang) {
  const isoLocale = isoLocales.find(locale => locale.key === lang) || null;

  return {
    token: process.env.GATSBY_API_TOKEN,
    with_language: isoLocale && isoLocale.iso,
  };
}

export function getClient() {
  const client = axios.create({
    baseURL: process.env.GATSBY_API_URL,
  });

  return client;
}

export function encryptMessage(message) {
  return CryptoJS.AES.encrypt(message, process.env.GATSBY_ENCRYPT_KEY).toString();
}

export function decryptMessage(messageEncrypted) {
  try {
    const bytes = CryptoJS.AES.decrypt(messageEncrypted, process.env.GATSBY_ENCRYPT_KEY);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (decrypted) return decrypted;
    else return 'Manipulated Data';
  } catch (e) {
    return 'Manipulated Data';
  }
}
