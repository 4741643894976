import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { throttle } from 'lodash';

import logoBig from '../images/logo-horsemanago.png';
import logoSmall from '../images/logo-horsemanago-partial.png';
import LokalizedLink from './shared/link';
import { setPage, setIsTablet, setIsSmallLaptop } from '../store/app';

const animationsStyles = [
  {
    top: 29,
    left: 55,
    height: 48,
  },
  {
    top: 25,
    left: 47,
    height: 46,
  },
  {
    top: 22,
    left: 39,
    height: 44,
  },
  {
    top: 18,
    left: 31,
    height: 43,
  },
  {
    top: 15,
    left: 23,
    height: 42,
  },
  {
    top: 11,
    left: 16,
    height: 41,
  },
  {
    top: 8,
    left: 10,
    height: 40,
  },
];

const AnimatedLogo = ({ pageName, isSmallLaptop, isTablet }) => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    function handleScroll() {
      const yPos = Math.round(window.scrollY / 20);
      setScrollY(yPos);
    }

    window.addEventListener('scroll', throttle(handleScroll, 100), false);

    return () => {
      window.removeEventListener('scroll', throttle(handleScroll, 100), false);
    };
  }, []);

  const getAnimationBreakpoints = (order = 'prev') => {
    if (order === 'prev') return scrollY > 1 ? scrollY - 1 : 0;
    else return scrollY < 6 ? scrollY : 6;
  };

  return (
    <motion.div
      style={{
        position: 'fixed',
        zIndex: '101',
      }}
      initial={animationsStyles[getAnimationBreakpoints('prev')]}
      animate={animationsStyles[getAnimationBreakpoints('next')]}
    >
      <LokalizedLink className={classnames({ 'cursor-default': pageName === 'home' })} to="/" id="logo">
        <img
          src={(isSmallLaptop || isTablet) && scrollY > 3 ? logoSmall : logoBig}
          alt="HORSEmanago"
          style={{ height: '100%' }}
        />
      </LokalizedLink>
    </motion.div>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
    isTablet: state.app.isTablet,
    isSmallLaptop: state.app.isSmallLaptop,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    setIsSmallLaptop: isSmallLaptop => dispatch(setIsSmallLaptop(isSmallLaptop)),
    setIsTablet: isTablet => dispatch(setIsTablet(isTablet)),
  })
)(AnimatedLogo);
