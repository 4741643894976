import React from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { locales } from '../../locales/constans';

const LocalizedLink = ({ to, langKey, children, className }) => {
  const path = locales.includes(langKey) ? `/${langKey}${to}` : to;
  return (
    <Link className={className} to={path}>
      {' '}
      {children}{' '}
    </Link>
  );
};

export default connect(state => ({ langKey: state.app.langKey, pageName: state.app.pageName }))(LocalizedLink);
