import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ListItem from './list-item';

const List = ({ horizontal, children, items, className }) => {
  const orientation = horizontal ? 'horizontal' : 'vertical';

  return (
    <div className={classnames('list', orientation, className)}>
      {children || items.map(item => <ListItem key={item.key} to={item.to} content={item.content} icon={item.icon} />)}
    </div>
  );
};

List.defaultProps = {
  horizontal: false,
  className: '',
  children: null,
  items: [],
};

List.propTypes = {
  horizontal: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      to: PropTypes.string,
    })
  ),
};

export default List;
