import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { RemoveScroll } from 'react-remove-scroll';
import Modal from 'react-modal';
import cls from 'classnames';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import Button from './shared/button';
import Flex from './shared/flex';
import CookieImage from '../images/cookie.png';
import BackIcon from '../images/goback.svg';
import { openModal, closeModal } from '../store/app';
import t from '../locales';
import { encryptMessage, decryptMessage } from '../utils/client';

const CookieConcent = ({ isMobile, isTablet, isOpen, openModal, closeModal, pageName }) => {
  // https://stackoverflow.com/questions/54861709/cookies-removeabc-not-working-in-reactjs/55593030
  const [cookies, setCookie, removeCookie] = useCookies();
  const hmlpCookie = cookies['hmlp-cookie-consent'] && decryptMessage(cookies['hmlp-cookie-consent']);
  const isPrivacyCookiesPage = typeof window !== 'undefined' && window.location?.pathname?.includes('cookies-policy');
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(isPrivacyCookiesPage);
  const [isAnalyticsApproved, setIsAnalyticsApproved] = useState(true);
  const [isAdvertisingApproved, setIsAdvertisingApproved] = useState(true);

  const disableAnalytics = () => {
    // Analytics cookies
    removeCookie('_ga', {
      path: '/',
      domain: '.horsemanago.com',
    });
    removeCookie('_gat', {
      path: '/',
      domain: '.horsemanago.com',
    });
    removeCookie('_gid', {
      path: '/',
      domain: '.horsemanago.com',
    });
    process.env.GATSBY_GTM_KEY &&
      removeCookie(`_gat_${process.env.GATSBY_ANALYTICS_KEY}`, {
        path: '/',
        domain: '.horsemanago.com',
      });
  };

  const disableAds = () => {
    removeCookie('_gcl_au', {
      path: '/',
      domain: '.horsemanago.com',
    }); // Google Adsense cookie
    removeCookie('_fbp', {
      path: '/',
      domain: '.horsemanago.com',
    }); // Facebook pixel cookie
  };

  useEffect(() => {
    setIsAdvancedOpen(isPrivacyCookiesPage);
  }, [pageName]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (['acceptAnalytics', 'acceptAdvertising', 'acceptAll'].includes(hmlpCookie)) {
      if (hmlpCookie !== 'acceptAnalytics') {
        if (process.env.GATSBY_GTM_KEY) {
          TagManager.initialize({
            gtmId: process.env.GATSBY_GTM_KEY,
            dataLayer: {
              pageType: window?.pageType,
            },
          });
        }
        if (process.env.GATSBY_ANALYTICS_KEY) {
          ReactGA.initialize(process.env.GATSBY_ANALYTICS_KEY);
          typeof window !== 'undefined' && ReactGA.pageview(window.location.pathname);
        }
      }
      if (hmlpCookie === 'acceptAnalytics') {
        disableAnalytics();
        disableAds();
      } else if (hmlpCookie === 'acceptAdvertising') {
        disableAnalytics();
      }
    } else {
      removeCookie('hmlp-cookie-consent', {
        path: '/',
      });
      disableAnalytics();
      disableAds();
      if (!isPrivacyCookiesPage) {
        openModal('MODAL_COOKIES');
        setIsAdvancedOpen(false);
      }
    }
  }, [hmlpCookie, isPrivacyCookiesPage]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveCookies = acceptAll => {
    let cookieValue = '';
    if ((isAdvertisingApproved && isAnalyticsApproved) || acceptAll) cookieValue = 'acceptAll';
    else if (isAnalyticsApproved) cookieValue = 'acceptAnalytics';
    else if (isAdvertisingApproved) cookieValue = 'acceptAdvertising';
    if (cookieValue) {
      setCookie('hmlp-cookie-consent', encryptMessage(cookieValue), {
        path: '/',
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 2), // 2 years
      });
    } else {
      removeCookie('hmlp-cookie-consent', {
        path: '/',
      });
    }
    closeModal('MODAL_COOKIES');
    // reset modal state
    setIsAdvancedOpen(isPrivacyCookiesPage);
    setIsAnalyticsApproved(true);
    setIsAdvertisingApproved(true);
  };

  const isSmallScreen = isMobile || isTablet;

  if (!isOpen) return null;

  // https://github.com/reactjs/react-modal/issues/133
  Modal.setAppElement('body');

  return ReactDOM.createPortal(
    <RemoveScroll>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={false}
        overlayClassName="cookie-modal-overlay"
        className="cookie-modal-content"
      >
        <Flex spaceBetween>
          <div>
            {isAdvancedOpen ? (
              <h1>{t('cookies.advancedSettings')}</h1>
            ) : (
              <React.Fragment>
                <div className="my-0 black">{t('cookies.dearUser')}</div>
                <h1>{t('cookies.weRespectPrivacy')}</h1>
              </React.Fragment>
            )}
          </div>
          <img src={CookieImage} alt="cookies" style={{ width: 41, height: 41 }} />
        </Flex>
        <div>
          {isAdvancedOpen ? (
            <React.Fragment>
              <p>{t('cookies.choosingOption')}</p>
              <Flex spaceBetween column={isSmallScreen}>
                <div className="py-3 px-3">{t('cookies.analyticsCookies')}</div>
                <Flex spaceBetween>
                  <Button
                    className={cls('link no-underline', {
                      'font-bold': !isAnalyticsApproved,
                    })}
                    onClick={() => {
                      setIsAnalyticsApproved(false);
                    }}
                  >
                    {t('cookies.noAccept')}
                  </Button>
                  <Button
                    className={cls('link no-underline', {
                      'font-bold': isAnalyticsApproved,
                    })}
                    onClick={() => {
                      setIsAnalyticsApproved(true);
                    }}
                  >
                    {t('cookies.accept')}
                  </Button>
                </Flex>
              </Flex>
              <Flex spaceBetween column={isSmallScreen}>
                <div className="py-3 px-3">{t('cookies.advertCookies')}</div>
                <Flex spaceBetween>
                  <Button
                    className={cls('link no-underline', {
                      'font-bold': !isAdvertisingApproved,
                    })}
                    onClick={() => {
                      setIsAdvertisingApproved(false);
                    }}
                  >
                    {t('cookies.noAccept')}
                  </Button>
                  <Button
                    className={cls('link no-underline', {
                      'font-bold': isAdvertisingApproved,
                    })}
                    onClick={() => {
                      setIsAdvertisingApproved(true);
                    }}
                  >
                    {t('cookies.accept')}
                  </Button>
                </Flex>
              </Flex>
              <Flex spaceBetween className="mt-6" column={isMobile}>
                <Button
                  onClick={() => {
                    setIsAdvancedOpen(false);
                  }}
                  className="link text-left font-bold"
                >
                  <Flex className="items-center">
                    <img src={BackIcon} alt="Go Back" style={{ width: 15, height: 15 }} />
                    {t('cookies.back')}
                  </Flex>
                </Button>
                <Button
                  onClick={() => handleSaveCookies(false)}
                  className={cls('teal normal-case', isMobile ? 'w-full' : 'w-1/2')}
                >
                  {t('cookies.saveAnd')}
                </Button>
              </Flex>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p className="mt-6">
                {t('cookies.weUseCookies')}
                <a className="text-teal underline" href="/pl/cookies-policy" target="_blank">
                  {t('cookies.cookiesUsingPolicy')}
                </a>
                ).
              </p>
              <p className="my-6">
                {t('cookies.clickingAccept')}
                <Button
                  onClick={() => {
                    setIsAdvancedOpen(true);
                  }}
                  className="link no-underline p-0"
                >
                  {t('general.here')}
                </Button>
                .
              </p>
              <Flex spaceBetween column={isMobile}>
                <Button
                  onClick={() => {
                    setIsAdvancedOpen(true);
                  }}
                  className="link text-left font-bold"
                >
                  {t('cookies.advancedSettings')}
                </Button>
                <Button
                  onClick={() => handleSaveCookies(true)}
                  className={cls('teal normal-case', isMobile ? 'w-full' : 'w-1/2')}
                >
                  {t('cookies.acceptAnd')}
                </Button>
              </Flex>
            </React.Fragment>
          )}
        </div>
      </Modal>
    </RemoveScroll>,
    document.getElementById('modal-root')
  );
};

export default connect(
  state => ({
    isMobile: state.app.isMobile,
    isTablet: state.app.isTablet,
    isOpen: state.app.MODAL_COOKIES.isOpen,
    pageName: state.app.pageName,
  }),
  dispatch => ({
    closeModal: modalName => dispatch(closeModal(modalName)),
    openModal: modalName => dispatch(openModal(modalName)),
  })
)(CookieConcent);
