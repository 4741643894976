import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';

import logo from '../images/logo-horsemanago.png';
import List from './shared/list';
import ListItem from './shared/list-item';
import t from '../locales';
import NavButton from './nav-button';
import Button from './shared/button';
import AnimatedHmLogo from './animated-hm-logo';
import LokalizedLink from './shared/link';

const Header = ({ isMobile, isMenuOpen, openMenu, pageName }) => {
  const [activeItem, setActiveItem] = useState(null);

  const menuItems = React.useMemo(
    () => [
      { key: 1, to: '/', content: t('menu.home') },
      { key: 2, to: '/pricing/', content: t('menu.pricing') },
      { key: 3, to: '/help/', content: t('menu.help') },
      { key: 4, to: '/about/', content: t('menu.about') },
      { key: 5, to: '/contact/', content: t('menu.contact') },
    ],
    []
  );

  useEffect(() => {
    setActiveItem(
      menuItems.find(item => {
        if (item.disable) return null;
        if (item.to.includes(pageName)) return item.key;
        if (item.to === '/' && pageName === 'home') return item.key;
        return null;
      })
    );
  }, [pageName, menuItems]);

  const goToHMApp = () => {
    if (typeof window === 'undefined') return null;
    window.location = 'https://app.horsemanago.com/';
  };

  return (
    <header className="inverted">
      <div className={classnames('container flex flex-row', isMobile ? 'justify-between' : 'justify-end')}>
        {isMobile ? (
          <>
            <LokalizedLink className={classnames({ 'cursor-default': pageName === 'home' })} to="/" id="logo">
              <img src={logo} alt="HORSEmanago" />
            </LokalizedLink>
            <NavButton open={isMenuOpen} onClick={openMenu} />
          </>
        ) : (
          <>
            <AnimatedHmLogo />
            <List className="languages-selector" horizontal>
              <ListItem disabled={pageName === 'register'}>
                <Button className="teal" to="/register" disabled={pageName === 'register'} small>
                  {t('general.registerTheStable')}
                </Button>
              </ListItem>
              <ListItem>
                <Button className="teal" onClick={goToHMApp} small>
                  {t('general.signIn')}
                </Button>
              </ListItem>
            </List>
          </>
        )}
      </div>
      {isMobile ? (
        <nav id="navigation" className={classnames({ open: isMenuOpen })}>
          <List horizontal>
            {menuItems.map(item => {
              const active = activeItem && item.key === activeItem.key;
              return (
                <ListItem
                  key={item.key}
                  to={!active ? item.to : ''}
                  content={item.content}
                  hint={item.hint}
                  className={classnames({ disable: item.disable, active })}
                />
              );
            })}
            <ListItem
              key="registerTheStable"
              to="/register"
              content={t('general.registerTheStable')}
              disabled={pageName === 'register'}
            />
            <ListItem key="singIn" onClick={goToHMApp} content={t('general.signIn')} />
          </List>
        </nav>
      ) : (
        <Sticky stickyStyle={{ zIndex: '100' }}>
          <nav id="navigation" className={classnames({ open: isMenuOpen })}>
            <List horizontal>
              {menuItems.map(item => {
                const active = activeItem && item.key === activeItem.key;
                return (
                  <ListItem
                    key={item.key}
                    to={!active ? item.to : ''}
                    content={item.content}
                    hint={item.hint}
                    className={classnames('bg-black', { disable: item.disable, active })}
                  />
                );
              })}
            </List>
          </nav>
        </Sticky>
      )}
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default connect(state => ({
  pageName: state.app.pageName,
  langKey: state.app.langKey,
  isMobile: state.app.isMobile,
}))(Header);
