import React from 'react';
import Link from './link';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { keyPressSpace } from '../../helpers';

const ListItem = ({ content, children, to, className, icon, hint, onClick, disabled }) => {
  const ContentItem = () => (
    <>
      {icon && <i className={icon} />}
      <span> {children || content} </span>
      {hint && <Hint />}
    </>
  );

  const Hint = () => (
    <div>
      <div className="arrow-up" />
      <div className="tooltip">{hint}</div>
    </div>
  );

  if (to)
    return (
      <Link to={to} className={classnames('item', className, { disabled: disabled })}>
        <ContentItem />
      </Link>
    );

  return (
    <div
      role="button"
      tabIndex={0}
      className={classnames('item', className, { disabled: disabled })}
      onClick={!!onClick && onClick}
      onKeyDown={e => keyPressSpace(e, onClick)}
    >
      <ContentItem />
    </div>
  );
};

ListItem.defaultProps = {
  to: null,
  children: null,
  content: '',
  className: '',
  icon: null,
  hint: '',
  onClick: () => {},
  disabled: false,
};

ListItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ListItem;
