import React from 'react';
import Layout from '../components/layout';
import { CookiesProvider } from 'react-cookie';

const initApp = ({ element, props }) => {
  return (
    <CookiesProvider>
      <Layout {...props}>{element}</Layout>
    </CookiesProvider>
  );
};

export default initApp;
