import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { compact } from 'lodash';

import Header from './header';
import PropTypes from 'prop-types';
import t from '../locales';
import favicon from '../images/favicons/favicons.ico';
import favicon32 from '../images/favicons/favicon-32x32.png';
import favicon64 from '../images/favicons/favicon-64x64.png';
import favicon128 from '../images/favicons/favicon-128x128.png';
import appletouch from '../images/favicons/apple-touch-icon.png';
import maskicon from '../images/favicons/safari-pinned-tab.svg';

import Footer from './footer';
import { setLang, setIsMobile, setIsTablet, setIsSmallLaptop } from '../store/app';
import { locales } from '../locales/constans';
import '../sass/layout.scss';
import CookieConsent from './cookie-concent';

export const mobileThreshold = 767;
export const tabletThreshold = 991;
export const smallLaptopThreshold = 1023;

const Layout = props => {
  const [isOpenMenu, openMobileMenu] = useState(false);
  const { children, pageName, langKey, setLang, setIsMobile, isMobile, setIsTablet, setIsSmallLaptop } = props;

  useEffect(() => {
    const updateWindowDimensions = () => {
      const mobileView = mobileThreshold > window.innerWidth;
      const tabletView = tabletThreshold > window.innerWidth && mobileThreshold <= window.innerWidth;
      const smallLaptopView = smallLaptopThreshold > window.innerWidth && tabletThreshold <= window.innerWidth;

      setIsSmallLaptop(smallLaptopView);
      setIsMobile(mobileView);
      setIsTablet(tabletView);
    };

    if (typeof window === 'undefined') return null;

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, [setIsMobile, setIsTablet, setIsSmallLaptop]);

  useEffect(() => {
    openMobileMenu(false);
  }, [langKey, pageName, isMobile]);

  let pathName = compact((props.uri || '').split('/'));
  if (locales.includes(pathName[0])) {
    setLang(pathName[0]);
    i18n.locale = pathName[0];
  } else {
    setLang('pl');
    i18n.locale = 'pl';
  }

  const toggleMenu = () => {
    openMobileMenu(!isOpenMenu);
  };
  const title = pageName ? t(`metaDatas.${pageName}.title`) : t(`metaDatas.home.title`);
  const description = pageName ? t(`metaDatas.${pageName}.description`) : t(`metaDatas.home.description`);
  const keywords = pageName ? t(`metaDatas.${pageName}.keywords`) : t(`metaDatas.home.keywords`);

  return (
    <>
      <Helmet
        link={[
          { rel: 'shortcut icon', href: favicon },
          { rel: 'apple-touch-icon', sizes: '180x180', href: appletouch },
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: favicon32 },
          { rel: 'icon', type: 'image/png', sizes: '64x64', href: favicon64 },
          { rel: 'icon', type: 'image/png', sizes: '128x128', href: favicon128 },
          { rel: 'mask-icon', color: 'teal', href: maskicon },
        ]}
        bodyAttributes={{ class: isOpenMenu && 'overflow-hidden' }}
        defer={false}
      >
        <html lang={langKey} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        {pageName === 'register-success' && <meta name="robots" content="noindex,nofollow" />}
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <Header isMenuOpen={isOpenMenu} openMenu={toggleMenu} />
      <div id="page">{children}</div>
      <div id="modal-root"></div>
      <Footer />
      <CookieConsent />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(
  state => ({
    langKey: state.app.langKey,
    pageName: state.app.pageName,
    isMobile: state.app.isMobile,
    isTablet: state.app.isTablet,
    isSmallLaptop: state.app.isSmallLaptop,
  }),
  dispatch => ({
    setLang: langKey => dispatch(setLang(langKey)),
    setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
    setIsTablet: isTablet => dispatch(setIsTablet(isTablet)),
    setIsSmallLaptop: isSmallLaptop => dispatch(setIsSmallLaptop(isSmallLaptop)),
  })
)(Layout);
