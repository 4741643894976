const SET_LANG = 'SET_LANG';
const SET_PAGE = 'SET_PAGE';
const SET_IS_MOBILE = 'SET_IS_MOBILE';
const SET_IS_TABLET = 'SET_IS_TABLET';
const SET_IS_SMALL_LAPTOP = 'SET_IS_SMALL_LAPTOP';

const initialState = {
  langKey: '',
  pageName: '',
  isMobile: null,
  isTablet: null,
  isSmallLaptop: null,
  MODAL_COOKIES: {
    isOpen: false,
  },
};

export const setLang = langKey => ({ type: SET_LANG, payload: langKey });
export const setPage = pageName => ({ type: SET_PAGE, payload: pageName });
export const setIsMobile = isMobile => ({
  type: SET_IS_MOBILE,
  payload: isMobile,
});
export const setIsTablet = isTablet => ({
  type: SET_IS_TABLET,
  payload: isTablet,
});

export const setIsSmallLaptop = isSmallLaptop => ({
  type: SET_IS_SMALL_LAPTOP,
  payload: isSmallLaptop,
});

export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const openModal = name => ({
  type: MODAL_OPEN,
  name,
});
export const closeModal = name => ({ type: MODAL_CLOSE, name });

const app = (state = initialState, action) => {
  const { type, payload, name } = action;
  switch (type) {
    case SET_LANG:
      return { ...state, langKey: payload };
    case SET_PAGE:
      return { ...state, pageName: payload };
    case SET_IS_MOBILE:
      return { ...state, isMobile: payload };
    case SET_IS_TABLET:
      return { ...state, isTablet: payload };
    case SET_IS_SMALL_LAPTOP:
      return { ...state, isSmallLaptop: payload };
    case MODAL_OPEN:
      return {
        ...state,
        [name]: {
          isOpen: true,
          props: payload || state[name]?.props || {},
        },
      };
    case MODAL_CLOSE:
      return {
        ...state,
        [name]: initialState[name],
      };
    default:
      return state;
  }
};

export default app;
